.modalContainer{
    height: 350px;
    width: 400px;
    position: absolute;
    background-color: transparent;
    overflow: hidden;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    
}

.modalSlider{
    height: 100%;
    width: 100%;
    position: absolute;
    transition: top 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.modal{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-backdrop-filter: blur(90px);
    backdrop-filter: blur(10px);
    background-color: rgba(224, 238, 240, 0.204)!important;
}

.modal img{
    height: auto;
}

.cursor, .cursorLabel{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #f5ca00;
    color: white;
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 300;
    pointer-events: none;
}

.cursorLabel{
    background-color: transparent;
}