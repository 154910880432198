

.main{

    display: flex;

    height: 100vh;

    align-items: center;

    justify-content: center;

}



.body h1{
    color: #f5ca00;
    margin-bottom: 2em;
}
.body, .body a{
    text-decoration: none;
    width: 70vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media (max-width: 768px) {
    
}