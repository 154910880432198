.project{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 50px 100px 50px 100px;

    border-top: 1px solid rgb(201, 201, 201);
    border-radius: 10px;


    cursor: pointer;
    transition: all 0.2s;
    color: white;
}
.project:last-of-type{
    border-bottom: 1px solid rgb(201, 201, 201);
}

.project:hover{
    opacity: 0.5;
}

.project:hover h2{
    transform: translateX(-10px);
    color: #fff;
}

.project:hover p{
    transform: translateX(10px);
}

.project h2{
    font-size: 40px;
    margin: 0px;
    font-weight: 400;
    transition: all 0.4s;
   
}

.project p{
    transition: all 0.4s;
    font-size: 20px;
    color: #f5ca00;
}

@media (max-width: 768px) {
    .project{
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 50px 0px 50px 0px;
    }
    .project h2{
        font-size: 30px;
       
    }
    
    .project p{
        font-size: 15px;
    }
    
}