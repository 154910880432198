@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


:root {
    --background: rgb(26, 29, 33);
    --secondColor: #f5ca00; 
    /* --background: #f2e9e4;
    --secondColor: #22223b; */
    --text: #fff; 
  }



*{
    margin : 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body{
    background: var(--background);
    overflow: scroll;
    height: 100vh;
    width: 100vw;
    /* scroll-snap-type: y mandatory;
    overflow-y: scroll; */
    cursor: wait;
    color: var(--text);
}

.container{
    /* scroll-snap-type: y mandatory; */
    /* overflow-y: scroll; */
    height: 100vh;
}



/* header and navbar */
.header{
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 20px 100px;
    display: none;
    justify-content: space-between;
    align-items: center;

    border-radius: 0px;

    /* transform in to border 50px top 5px back color rgba(224, 238, 240, 0.202);*/
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    transition: 0.4s;
    z-index: 99;
}
.header span{
    color: var(--secondColor);
}

.logo{
    color: var(--text);
    font-size: 25px;
    text-decoration: none;
    font-weight: 600;
    cursor:default;
}
.navbar a{
    color: var(--text);
    font-size: 18px;
    text-decoration: none;
    margin-left: 35px;
    transition: 0.4s;
}
.nav-bg{
    position: fixed;
    top: 8%;
    left: 0;
    width: 100%;
    height: 246px;
    background: rgba(255, 255, 255, .1);
    border-bottom: 2px solid rgba(255, 255, 255, .2);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 98;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    display: none;
    
}



.navbar a:hover{
    font-weight: 600;
    color: var(--secondColor);
}

#menu-icon{
    font-size: 36px;
    color: var(--text);
    display: none;
    z-index: 100;
}


@media (max-width: 992px) {
    .header{
        padding: 1.25rem 4%;
    }
}
@media (max-width: 768px) {
    
    #menu-icon{
        display: block;
    }
    .navbar{
        position: fixed;
        top: 100%;
        left: 0;
        width: 100%;
        padding: .5rem 4%;
        background:rgba(255, 255, 255, .1) ;
        border-bottom: 2px solid rgba(255, 255, 255, .2) ;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        border-bottom-left-radius: 50px !important;
        border-bottom-right-radius: 50px !important;
        display: none;
        
    }
    .navbar.active{
        display: block;
    }
    .navbar a {
        display: block;
        margin:1.5rem 0;
    }
    .header.active{
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    .nav-bg.active{
        display: block;
    }
    
    
}





/* general sections  */
section{
    min-height: 100vh;
    background-color: var(--background);
    color:var(--text);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 25px;
    transition: 0.3s;
    /* scroll-snap-align: start !important; */
}





#Home{
    background-color:var(--background);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 35px;
    color:var(--text);
    transition: 0.3s;
    /* background-image: url(images/matrix.webp); */
    background-repeat: no-repeat;
    background-size: cover;
}
#Home span{
    color: var(--secondColor) !important;    
}

#Home p {
    display: flex;
    position: absolute;
    justify-content: space-between;
    min-width: 20vw;
    margin-top: 10px;
    word-spacing: 10px;
    bottom: 2vh;
}

.fa-js{color: #FFD43B;}
.fa-react{color: #74C0FC;}
.fa-html5{color: #ff6a00;}
.fa-css3-alt{color: #0061ff;}
.fa-java{color: #ff4013;}
.fa-python{color: #FFD43B;}
.fa-node-js{color: #96d35f;}


.mask{
    display: flex;
    align-items: center;
    justify-content: center;
    height:100vh;
    width: 100vw;
    position:absolute;
    mask-image: url('../public/images/Ellipse.svg');
    background-color: var(--secondColor);
    mask-repeat: no-repeat;
    mask-size: 40px;
    text-align: center;
    color:var(--text);
    font-size: 1.5em;
}
.mask span{
    color: var(--background);
}
@media (max-width: 768px) {
    
    #Home{
        font-size: 25px;
    }
    #Home p{
        font-size: 25px;
    }
    .mask{
        display: none;
    }
}






/* ABOUT SECTION */








#About .container {
    height: fit-content;
    width: 80%;
    margin-top: 80px;
    padding-top: 20px;
    padding-bottom: 50px;
    background-color: rgba(38, 45, 55, 0.596);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 50px;
    
}
#About .container .title{
    flex-grow: 5;
    padding-bottom: 50px;
    font-weight: 800;
    color: var(--secondColor);
    font-size: 40px;
}
#About .container .title span{
    font-size: 15px;
    font-weight: 100;
    color: silver;
}

#About .container .h3{
    width: 100%;
    justify-content: space-between;
}

/* content */
#About .content{
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:space-evenly;
    text-align: left;
    font-size: 20px;
    

}
#About .content h1{
    text-align: left;
    color: var(--text);
    font-size: 18px;
    font-weight: 200;
}
#About .content h1 span{
    font-size: 40px;
    font-weight: 400;
}

/* description */
#About .description{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-basis: 100%;
    flex: 1;
    min-width: 276px;
    font-weight: 200;
    color: silver;
}

.socials{
    display: flex;
    justify-content: start;
}
.socials .fa-brands, .socials .fa{
    font-size: 33px;
    width: fit-content;
}
.socials .fa-brands span, .socials .fa span{
    font-size:15px;
    text-decoration: none;
    font-weight: 300;
    color: silver;
    margin-right: 5px;

}
.socials .fa-brands:hover, .socials .fa:hover{
    color: yellow !important;
}

#About .description .cardsContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
    margin: 0;
}

#About .description .cardsContainer .card{
    flex: 1 1 auto;
    border: 1px solid silver;
    padding: 10px ;
    margin-right: 3vw;
    margin: 1vh;
    border-radius: 30px;
    text-align: center;
    min-width: 70px;
}

#About .description .cardsContainer .card:hover{
    background:rgba(224, 238, 240, 0.204);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.description .cardsContainer .card h3{
    font-weight: 500;
    font-size: 15px;
}
.description .cardsContainer .card p{
    font-weight: 300;
    font-size: 13px;
}



/* picture settings */
#About .profilePic{
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    justify-content: start;
    align-content: center;
    align-items: center;
    padding: 20px 20px 20px 20px;
}

#About .picture{
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    width: 60%;
    animation: morph 8s ease-in-out infinite;
    transition: all 1s ease-in-out;
    border:4px solid var(--secondColor) ;
    
}


@media (max-width: 768px) {
    #About{
        padding-top: 20px;
    }
    #About .profilePic{ 
        flex: none;
        max-width: fit-content;
    }
    #About .picture{
        min-width: 40%;
        /* max-width: 40%; */
    }
    #About .description, #About .content h1{
        text-align: center;
    }
    #About .container {
        width: 90%;
    }
    #About .description .cardsContainer{
        justify-content:space-around;

    }
    #About .description .cardsContainer .card{
        margin: 10px;
        min-width: fit-content;
    }
    .socials .fa-brands, .socials .fa{
        display: block;
        padding: 1vh;
    }
    .socials{
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
}
@keyframes morph {
    0% {
              border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
      }
      50% {
              border-radius:  30% 60% 70% 40% / 50% 60% 30% 60%;
      }
    
      100% {
          border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
      }
  }








  /* PROJECTS SECTION */

#Projects{
    background-color: var(--background);
    padding-top: 100px;
    min-height:auto;
    
}

#Projects > h1{
    padding-bottom: 50px;
    font-weight: 800;
    color: var(--secondColor);
    font-size: 40px;
 
}
#Projects .container{
    height: auto;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-flow: wrap;
    
    /* background-color: blue; */

}

.container .card:nth-child(even){
    margin-top: 50px;
}

#Projects  .card{
    height: fit-content;
    width: 45%;
    min-width: 350px;
    padding-top:0px;
    padding-bottom: 50px;
    background-color: rgba(38, 45, 55, 0.596);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 50px;
    display: block;
    
    /* align-items: center;
    justify-content: center; */
}
#Projects .card p{
    font-size: 20px;
    font-weight: 200;
}


.cardImage{
    height: 300px;
    margin: 50px 10px 0 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .cardTitle
{
    padding: 0 30px 0 30px;
    text-align: left;
}

@media (max-width: 768px) {
    #Projects {
        min-height: 200%;
    }
    .container .card:nth-child(even){
        margin-top: 20px;
    }
    #Projects .container{
        width: 90%;
    }
    #Projects  .card{
        width: 100%;
    }
    .container .card{
        margin-top: 20px;
    }
}



.bubble_container{
    width: 75%;

    margin: 100px 200px 0;

}

.bubbles{
    position: relative;
    display: flex;
    max-width: 100vw;
    
}

.bubbles span{
    position: relative;
    width: 30px;
    height: 30px;
    background: var(--secondColor);
    margin: 0 4px;
    border-radius: 50%;
    box-shadow: 0 0 0 10px #f5c80094,
    0 0 50px var(--secondColor),
    0 0 100px var(--secondColor);
    animation: animate 15s linear infinite;
    animation-duration: calc(125s / var(--i));
}
.bubbles span:nth-child(even){
    background: var(--secondColor);
    box-shadow: 0 0 0 10px #f5c80094,
    0 0 50px var(--secondColor),
    0 0 100px var(--secondColor);
}
@keyframes animate {
    0%{
        transform: translateY(100vh) scale(0);
    }
    100%{
        transform: translateY(13vh) scale(1);
    }
}



.recaptcha-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;

}


#Contacts .container{
    height: fit-content;
    width: 80%;
    margin-top: 80px;
    padding-top: 20px;
    padding-bottom: 50px;
    background-color: rgba(38, 45, 55, 0.596);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(5px);
    border-radius: 50px;
}

#Contacts .container h1{
    flex-grow: 5;
    padding-bottom: 50px;
    font-weight: 800;
    color: var(--secondColor);
    font-size: 40px;
}

#Contacts h3{
    font-weight: 100;
    font-size: 15px;
    color: silver;
}

#Contacts input{
    background-color: rgb(255, 255, 255, .1);
    border-radius: 20px;
    border: 1px solid var(--secondColor);
    /* transform in to border 50px top 5px back color rgba(224, 238, 240, 0.202);*/
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    color: white;
    padding: 10px 20px;
    min-width: 40vw;
    min-height: 50px;
    font-size: 20px;
    font-weight: 100;

}
#Contacts input:focus{
    outline: 2px solid var(--secondColor);
}

textarea{
    background-color: rgb(255, 255, 255, .1);
    border-radius: 30px;
    /* transform in to border 50px top 5px back color rgba(224, 238, 240, 0.202);*/
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    color: white;
    padding: 10px 20px;
    min-width: 40vw;
    min-height: 200px;
    resize: none;
    overflow: scroll;
    border: 1px solid var(--secondColor);
    font-size: 20px;

 
}
textarea:focus{
    outline: 2px solid var(--secondColor);
}

@media (max-width: 768px) {
    .bubbles span{
        height: 5px;
    }
    #Contacts .container{
        width: 90%;
    }
    #Contacts input , textarea{
        width: 89%;
    }
    

}

i#Contacts input:invalid{
    color: red !important;
    outline: red 3px solid;
}

label{
    font-size: 20px;
    font-weight: 200;
}

button{
    background-color: var(--secondColor);
    border-radius: 50px;
    border: 1px solid var(--secondColor);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    color: var(--background);
    padding: 4px 5px;
    min-width: 40vw;
    min-height: 50px;
    font-size: 20px;
    cursor: pointer;
}
button:hover{
    background-color: var(--background);
    color: var(--secondColor);
}


footer{
    margin: top 1vh;;
    /* position:absolute; */
    color: silver;
    text-align: center;
    font-size: 15px;
    padding: 3rem;
    width: 100%;
}

/* Floating Social Media Bar Style Starts Here */

.fl-fl {
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 4px;
    width: 190px;
    position: fixed;
    right: -150px;
    z-index: 1000;
    font: normal normal 10px Arial;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
    border-radius: 10px;
    background:rgba(224, 238, 240, 0.204);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
  
  .fl-fl .fa {
    font-size: 20px;
    color: #fff;
    padding: 10px 0;
    width: 40px;
  }
  
  .fl-fl:hover {
    right: 0;
  }
  
  .fl-fl a {
    color: #fff !important;
    text-decoration: none;
    text-align: center;
    /* line-height: 43px!important; */
    vertical-align: center!important;
  }

  .fl-fl i{
    color: #fff;
  }
  .float-fi {
    bottom: 10px;
  }
  .float-fi:hover {
    color: black !important;
  }
  .float-git {
    /* background: #070b15; */
    bottom: 70px;
  }
  .float-git:hover {
    background: #070b15;
  }
  .float-in {
    bottom: 130px;
  }
  .float-in:hover{
    background: #0762C8;
  }


 
/* 
#Projects .container {
    height: 400px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;
}

.card {
    width: 80px;
    border-radius: .75rem;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    border-radius: 2rem;
    margin: 0 10px;
    display: flex;
    align-items: flex-end;
    transition: .6s cubic-bezier(.28,-0.03,0,.99);
    box-shadow: 0px 10px 30px -5px rgba(0,0,0,0.8);
}

.card > .row {
    color: white;
    display: flex;
    flex-wrap: nowrap;
}

.card > .row > .icon {
    background: #223;
    color: white;
    border-radius: 50%;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
}

.card > .row > .description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    height: 80px;
    width: 520px;
    opacity: 0;
    transform: translateY(30px);
    transition-delay: .3s;
    transition: all .3s ease;
}

.description p {
    color: #b0b0ba;
    padding-top: 5px;
}

.description h4 {
    text-transform: uppercase;
}

input {
    display: none;
}

input:checked + label {
    width: 600px;
}

input:checked + label .description {
    opacity: 1 !important;
    transform: translateY(0) !important;
}

.card[for="c1"] {
    background-image: url(images/portfolio.png)
}
.card[for="c2"] {
    background-image: url(images/portfolio.png)
}
.card[for="c3"] {
    background-image: url(images/portfolio.png)
}
.card[for="c4"] {
    background-image: url(images/portfolio.png)
} */